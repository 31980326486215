<template>
  <dok-downdrop>
    <template v-slot:button>
      <div class="relative">
        <div
          style="
            width: 10px;
            height: 10px;
            background: #fd5b68;
            left: -3px;
            top: 0px;
          "
          class="absolute rounded-full animate-ping"
          v-if="circlePing"
        ></div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="#767676"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            d="M23 7l-7 5 7 5V7zM14 5H3c-1.105 0-2 .895-2 2v10c0 1.105.895 2 2 2h11c1.105 0 2-.895 2-2V7c0-1.105-.895-2-2-2z"
          />
        </svg>
      </div>
    </template>

    <template v-slot:content>
      <div class="flex flex-col gap-y-2" v-if="data.length">
        <div
          v-for="(up, index) in data"
          :key="index"
          class="p-3 border-2 border-solid rounded-cu flex flex-col gap-y-2"
          :class="up.dismissed ? 'opacity-75 cursor-not-allowed' : ''"
          style="border-color: #f1f1f1"
        >
          <div class="flex flex-row items-start gap-x-5">
            <div class="relative">
              <img src="/svg/videocons.svg" />
            </div>
            <div class="flex flex-col gap-y-2 w-full">
              <div class="flex flex-row gap-x-3 flex-1">
                <div class="flex flex-row gap-x-2">
                  <img src="/svg/calendar_sm.svg" style="height: 16px" />
                  <span class="font-EffraR text-base">{{
                    _moment()(up.startAt).format("DD MMM")
                  }}</span>
                </div>
                <div class="flex flex-row gap-x-2">
                  <img src="/svg/clock_sm.svg" style="height: 16px" />
                  <span class="font-EffraR text-base">{{
                    _moment()(up.startAt).format("HH:mm")
                  }}</span>
                </div>
              </div>
              <h1
                class="
                font-EffraM
                text-lg text-black
                flex
                items-center
                flex-row
                gap-x-2
              "
              >
                {{ `${up.patient_firstName} ${up.patient_lastName}` }}
              </h1>
              <span
                class="
                border border-solid
                rounded-full
                font-EffraR
                text-base
                py-1
                px-3
                border-indigo-700
                text-indigo-700
              "
              >
                Video Consultation
              </span>
            </div>
            <div
              :class="up.isPaid ? 'text-green-500' : 'text-rosDok-ultra'"
              class="font-EffraR text-sm"
            >
              {{ up.isPaid ? "Payée" : "Impayée" }}
            </div>
          </div>
          <div
            class="font-EffraR text-base text-grayDok-sidebar"
            v-if="!start[index] && index === 0"
          >
            Débute dans:
            {{
              `${countdown[index].hours || "00"}:${countdown[index].mintues ||
                "00"}:${countdown[index].seconds || "00"}`
            }}
          </div>
          <div class="flex flex-row gap-x-1">
            <dok-button
              v-if="!up.dismissed"
              size="sm"
              :bg="up.isPaid || up.initPrice === 0 ? 'blue' : 'gray'"
              :custom-class="up.isPaid ? 'w-full' : 'w-full cursor-not-allowed'"
              @click.native="$router.push(`/consultations/${up._id}`)"
              >Lancer la téléconsultation</dok-button
            >
            <dok-button
              v-if="countdown[index].mintues < -4 && !up.dismissed"
              size="sm"
              :bg="'rose'"
              @click.native="onCloseVideoCall(up)"
            >
              Fermer
            </dok-button>
          </div>
        </div>
      </div>
    </template>
  </dok-downdrop>
</template>

<script>
import { EventBus } from "@/event-bus";
import moment from "moment";

export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      start: [],
      countdown: []
    };
  },
  computed: {
    circlePing() {
      return this.data.filter(data => !data.dismissed).length;
    }
  },
  mounted() {
    this.data.map((ev, index) => {
      this.countDown(ev.startAt, index);
    });

    EventBus.$on("RefreshCountDown", () => {
      this.$forceUpdate();
    });
  },
  methods: {
    _moment() {
      return moment;
    },
    countDown(startAt, index) {
      let eventDate = moment(startAt).unix();
      let currentDate = moment().unix();

      let diffTime = eventDate - currentDate;
      let duration = moment.duration(diffTime * 1000, "milliseconds");

      let intval = setInterval(() => {
        duration = moment.duration(duration - 1000, "milliseconds");

        this.countdown[index] = {
          hours: duration.hours(),
          mintues: duration.minutes(),
          seconds: duration.seconds()
        };

        if (duration.minutes() <= 0) {
          this.start[index] = true;
        }

        if (
          duration.hours() <= 0 &&
          duration.minutes() <= -5 &&
          duration.seconds()
        ) {
          clearInterval(intval);
        }

        EventBus.$emit("RefreshCountDown");
      }, 1000);
    },
    async onStartVideoCall(data) {
      if (!data.isPaid) {
        EventBus.$emit("flashDok", {
          type: "errorr",
          message: `${data.patient_lastName} ${data.patient_firstName} n'a pas encore effectué le paiement de la téléconsultation. Si vous voulez tout de même la lancer, vous pouvez le faire depuis la messagerie.`,
          timerAlert: 8000
        });
        return;
      }
      try {
        await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true
        });

        if (!data.conversationId) {
          return;
        }

        EventBus.$emit("collectionVideoCall", {
          token: "",
          conversationId: data.conversationId,
          onVideoCallClicked: () => {}
        });
      } catch (err) {
        return EventBus.$emit("flashDok", {
          type: "errorr",
          message: "Merci d'autoriser l’accès à votre caméra et à votre micro",
          timerAlert: 8000
        });
      }
    },
    async onCloseVideoCall(xevent) {
      this.$confirm({
        message: `Êtes-vous sûr(e)?`,
        button: {
          no: "Non",
          yes: "Oui"
        },
        callback: async confirm => {
          if (confirm) {
            this.$vs.loading();

            const { ok, data } = await this.$store.dispatch(
              "agenda/EVENT_CHANGE_VISIBLILITY",
              {
                id: xevent._id,
                status: "dismiss"
              }
            );

            if (ok) {
              EventBus.$emit("CHNAGE_VISIBLITY", data);
            }

            this.$vs.loading.close();
          }
        }
      });
    }
  }
};
</script>
